import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import RingpoemsIntro from './ringpoemsIntro'

// markup
const Posies_m = () => {
  return (
   <Layout >
      <Head title='Ring Poems (Posies) in English - M-T'/>
     
     <RingpoemsIntro />
<h2>M - S</h2>
<br/>
MANY ARE THE STARRS I SEE <br/>
YET IN MY EYE NO STARR LIKE THEE &nbsp;<br/>
<br/>
MEND YOUR WAYS LOVE ALL YOUR DAYES &nbsp;<br/>
<br/>
MEMENTO MEI <br/>
WHEN THIS YOU SEE <br/>
REMEMBER ME &nbsp;<br/>
<br/>
MINE EYE DID SEE <br/>
MY HEART DID CHOOSE <br/>
TRUE LOVE DOTH BIND <br/>
TILL DEATH DOTH LOOSE &nbsp;<br/>
<br/>
<br/>
MOST IN MYND AND YU MYN HEART <br/>
LOTHEST FROM YOU FOR TO DEPARTT &nbsp;<br/>
<br/>
MY DEAREST BETTY IS GOOD AND PRETTY &nbsp;<br/>
<br/>
MY FAITH I BIND WHERE LOVE I FIND &nbsp;<br/>
<br/>
MY FAITH IS GIVEN <br/>
THIS PLEDGE DOTH SHOW <br/>
A WORK FROM HEAVEN <br/>
PERFORMED BELOW &nbsp;<br/>
<br/>
MY FOND DELIGHT BY DAY AND NIGHT &nbsp;<br/>
<br/>
MY GIVING THIS <br/>
BEGINS MY BLISS &nbsp;<br/>
<br/>
MY HAPPY CHOYCE MAKES MEE REJOYCE &nbsp;<br/>
<br/>
MY HARTAND I UNTILL I DY &nbsp;<br/>
<br/>
MY HEART I BIND WHERE FAITH I FIND &nbsp;<br/>
<br/>
MY HEART LIVES WHERE IT LOVES &nbsp;<br/>
<br/>
MY HEART MY DEAR <br/>
TO THEE I GIVE <br/>
FOR THEE TO KEEP <br/>
WHILST BOTH WE LIVE &nbsp;<br/>
<br/>
<br/>
MY HEART YOU HAVE AND YOURS I CRAVE &nbsp;<br/>
<br/>
MY LOVE FOR THEE SHALL ENDLESS BE <br/>
<br/>
<br/>
MY LUVE IS FIXT <br/>
I CANNOT RANGE <br/>
I LIKE MY CHOICE <br/>
TO WELL TO CHANGE &nbsp;<br/>
<br/>
MY LOVE IS TRUE MY DEAR TO YOU &nbsp;<br/>
<br/>
MY LOVE IS TRUE TO NONE BUT YOU &nbsp;<br/>
<br/>
MY LOVE NO LESS THEN I PROFESS &nbsp;<br/>
<br/>
MY LOVE THESE BR/ACELETS TAKE <br/>
AND THINK OF THEM NO HARM <br/>
BUT SINCE THEY BR/ACELETS BE <br/>
LET THEM IMBR/ACE THY ARM. &nbsp;<br/>
<br/>
MY LOVE TO THEE LIKE THIS SHALL BE &nbsp;<br/>
<br/>
MY LOUE TO THEE SHALL ENDLESS BE &nbsp;<br/>
<br/>
MY LOVE TO THEE SHALL EVER BE &nbsp;<br/>
<br/>
MY PROMISE PAST SHALL ALWAYS LAST &nbsp;<br/>
<br/>
NERE JOY THE HEART THAT SEEKS TO PART &nbsp;<br/>
<br/>
NO BITTER SMART CAN CHANGE MY HEART &nbsp;<br/>
<br/>
NO CROSSE SO STRANGE MY LOVE TO CHANGE &nbsp;<br/>
<br/>
NO GIFT CAN SHOW THE LOVE I OW &nbsp;<br/>
<br/>
NO HAP SO HARD AS LOVE DEBARD &nbsp;<br/>
<br/>
NOE HEART MORE TRUE THAN MINE TO YOU &nbsp;<br/>
<br/>
NO LOVE MORE TRUE THAN MINE TO YOU &nbsp;<br/>
<br/>
NOE STAR TO ME SOE BR/IGHT AS THEE &nbsp;<br/>
<br/>
NO TURTLE DOVE HATH PURER LOVE &nbsp;<br/>
<br/>
NOT LUST BUT LOVE AS TIME SHALL PROVE &nbsp;<br/>
<br/>
NOT THINE NOR MINE BUT OURS &nbsp;<br/>
<br/>
NOT TWO BUT ONE TILL LIFE BE DONE &nbsp;<br/>
<br/>
OF ALL BAD THINGS <br/>
A HEART WITH WINGS <br/>
IS STILL THE WORST <br/>
AND HE THAT MEETS <br/>
WITH ONE SO FLEET <br/>
OF ALL'S ACCURST &nbsp;<br/>
<br/>
OF ALL THE REST I LOVE THEE BEST &nbsp;<br/>
<br/>
OF RAPTUROUS JOYE I AM THE TOYE &nbsp;<br/>
<br/>
OF SUCH A TREASURE THEN ART THOU POSSEST <br/>
FOR THOU HAST SUCH A HEART IN SUCH A BR/EST &nbsp;<br/>
<br/>
<br/>
ON THY RETURN FROM SEE <br/>
UNITED WEE WILL BEE &nbsp;<br/>
<br/>
&nbsp;ONE LOVE ONE TROTH <br/>
BETWEEN US BOTH &nbsp;<br/>
<br/>
ONE WAS THE BOW ONE WAS THE DART <br/>
THAT WOUNDED US BOTH TO THE HEART <br/>
THEN SINCE WE BOTH DO FEEL ONE PAIN <br/>
LET ONE LOVE CURE US BOTH AGAIN &nbsp;<br/>
<br/>
ONE WORD FOR ALL I LOVE AND SHALL &nbsp;<br/>
<br/>
PARTING IS PAYNE WHEN <br/>
LOVE DOTH REMAYNE &nbsp;<br/>
<br/>
PRAY TAKE ME KINDLY MISTRESS KISSE ME TOO <br/>
MY MASTER SWEARES HELE DO AS MUCH FOR YOU &nbsp;<br/>
<br/>
PREPARED BE TO FOLLOW ME &nbsp;<br/>
<br/>
RATHER DYE THAN FAITH DENY &nbsp;<br/>
<br/>
RECEIVE THIS SACRIFICE IN PART <br/>
FROM THE ALTAR - OF MY HEART &nbsp;<br/>
<br/>
REMEMBER HIM WHO DIED FOR THEE <br/>
AND AFTER THAT REMEMBER ME &nbsp;<br/>
<br/>
REMEMBER MEE WHEN THIS YOU SEE <br/>
<br/>
REMEMBER THIS AND GIVE A KISSE &nbsp;<br/>
<br/>
RINGS AND TRUE FRIENDS <br/>
ARE WITHOUT ENDS <br/>
<br/>
ROSEMARY ROSE I SEND TO THEE <br/>
IN HOPE THAT THOU WILT MARRY ME <br/>
NOTHING CAN BE SWEET ROSE <br/>
MORE SWEETER UNTO HARRY <br/>
THEN MARRY ROSE <br/>
SWEETER THAN THIS ROSEMARY &nbsp;<br/>
<br/>
RYCHES BE UNSTABLE <br/>
BEUTY WYLL DEKAY <br/>
BUT FAITHFULL LOVE <br/>
WYLL EVER LASTE <br/>
TYL DETH DRIVE ITT AWAY &nbsp;<br/>
<br/>
SHE THAT OF ALL DOTH <br/>
LOVE THE DEAREST <br/>
DOTH SEND THEE THIS <br/>
WHICH AS THOU WEAREST <br/>
AND OFT DO'S LOOK <br/>
ON THINK ON ME <br/>
AS I BY THINE <br/>
DO THINK ON THEE &nbsp;<br/>
<br/>
SINCE GOD HATH WROUGHT <br/>
THIS CHOICE IN THEE <br/>
SO FRAME THYSELF <br/>
TO COMFORT ME &nbsp;<br/>
<br/>
SINCE THY HOT LOVE <br/>
SO QUICKLY'S DONE <br/>
DO THOU BUT GOE <br/>
FLE STRIVE TO RUN &nbsp;<br/>
<br/>
SUCH LIKING IN MY CHOICE I HAVE <br/>
NOTHING SHALL PART US BUT THE GRAVE &nbsp;<br/>
<br/>
SUE IS BONNY BLYTHE AND BR/OWN <br/>
THIS RING HATH MADE HER NOW MY OWN &nbsp;<br/>
<br/>
SWEETHEART I PRAY DOE NOT SAY NAY <br/>

<br/>
<hr/>
   </Layout>
  )
}

export default Posies_m
